import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom";

import { Layout } from "layout";
import { RootPage } from "pages/root";

const Routes = [
    {
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <RootPage />
            }
        ]
    }
] as Array<RouteObject>;

const Router = createBrowserRouter(Routes);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLDivElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={Router} />
    </React.StrictMode>
);